import React, { useEffect } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import 'react-native-gesture-handler';
import { QueryClient, QueryClientProvider } from "react-query";
import { Loading } from "./src/components/Loading";
import { pt, registerTranslation } from 'react-native-paper-dates'
import {
  useFonts,
  Roboto_400Regular,
  Roboto_500Medium,
  Roboto_700Bold,
} from '@expo-google-fonts/roboto'
import { StatusBar } from "react-native";
import AppScreen from './src/screens/App'

registerTranslation('pt-GB', pt)

const App = () => { 

  const [fontsLoaded] = useFonts({
    Roboto_400Regular,
    Roboto_500Medium,
    Roboto_700Bold,
  })

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <SafeAreaProvider>
      <QueryClientProvider client={queryClient}>
        <StatusBar
          barStyle="dark-content"
          backgroundColor="transparent"
          translucent
        />
        {fontsLoaded ? <AppScreen /> : <Loading />}
      </QueryClientProvider>
    </SafeAreaProvider>
  );
};

export default App;
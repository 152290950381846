import { useQuery, gql, makeVar } from '@apollo/client';

import { Config } from '@configs/Config';
import { useAuth } from '@contexts/Auth';
import { useCustomer } from '@contexts/CustomerContext';
import { IntegrationEnum } from '@enums/IntegrationEnum';
import { SortEnum } from '@enums/SortEnum';
import { AvailableIndustriesByUserSortType } from '@types/CommercialPolicyProductSortType';
import { IndustryDataType } from '@types/IndustryDataType';

const CUSTOMER_AVAILABLE_INDUSTRIES = gql`
  query availableIndustriesByUser(
    $customers: [CustomerInputType!]
    $integrations: [IntegrationEnum!]
    $sort: CustomerAvailableIndustriesSortType
    $limit: Int
    $offset: Int
  ) {
    availableIndustriesByUser(
      customers: $customers
      integrations: $integrations
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      pageInfo {
        pageCount
        totalCount
        hasNextPage
      }
      edges {
        node {
          industryCode
          name
          isEnabled
        }
      }
    }
  }
`;

interface Props {
  limit: number;
  offset: number;
}

interface AvailableIndustriesByUserArgs {
  customer: {
    code: string;
  };
  integrations: IntegrationEnum[];
  sort?: AvailableIndustriesByUserSortType;
  limit: number;
  offset: number;
}

export const industryItemsVar = makeVar<IndustryDataType[]>([]);

export function useAvailableIndustriesByUser({ limit, offset }: Props) {
  const { user } = useAuth();
  const { customerCode } = useCustomer();

  const args: AvailableIndustriesByUserArgs = {
    customer: {
      code: customerCode !== "" ? customerCode : user.getUserCode()
    },
    integrations: [IntegrationEnum.POINT_OF_SALES],
    sort: {
      industry: {
        code: SortEnum.ASC,
      }
    },
    limit,
    offset
  }

  return useQuery(CUSTOMER_AVAILABLE_INDUSTRIES, {
    variables: args,
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      console.log(data);
      const parsedData = [
        ...industryItemsVar(),
        ...refactorResult(data.availableIndustriesByUser)
      ];

      industryItemsVar(parsedData);
    }
  });
};

interface AvailableIndustriesByUserType {
  pageInfo: {
    pageCount: number;
    totalCount: number;
  },
  edges: {
    node: CustomerAvailableIndustryProps;
  }[];
}

interface CustomerAvailableIndustryProps {
  industryCode: string;
  name: string;
  isEnabled: boolean;
}

export function refactorResult(industries: AvailableIndustriesByUserType): IndustryDataType[] {
  const parsedIndustries = (industries?.edges || []).map((edge: { node: CustomerAvailableIndustryProps }) => {
    const industry: CustomerAvailableIndustryProps = edge.node;
    const imageUrl = `${Config.URL_CDN_BASE}/${industry.name}/logo.jpg`;

    return {
      url: imageUrl,
      industry: industry.industryCode,
      nameIndustry: industry.name
    };
  });

  return parsedIndustries;
};